export const extractTenantProps = (tenant: Record<string, unknown>) => {
  const props = tenant?.props;

  if (typeof props === "object" && props !== null) {
    return props;
  }

  if (typeof props === "string") {
    try {
      return JSON.parse(props);
    } catch (e) {
      console.error("Error parsing Json:", e);
    }
  }

  return {};
};
