import { cloneDeep, forIn } from "lodash";

import { UserSettingsConfig } from "@/classes/UserSettingsManagement";
import { USER_SETTINGS_VERSION } from "@/settings/userDefaultSettings";
import { getChartSettings } from "@/settings/widgets";

export default {
  version: USER_SETTINGS_VERSION,
  update: function (prevSettings: UserSettingsConfig) {
    const preparedSettings = cloneDeep(prevSettings);
    preparedSettings.dashboards.forEach((dashboard) => {
      const dashboardPrepared = dashboard.layout.filter((widgetConfig) => {
        const { chartType, family } = widgetConfig.content || {};
        try {
          const chartSettingsFromConfig = getChartSettings(chartType, family);
          return !!chartSettingsFromConfig;
        } catch (e) {
          return false;
        }
      });
      const updatedLayout = dashboardPrepared.map((widgetConfig) => {
        const { settings, chartType, family } = widgetConfig.content;
        const chartSettingsFromConfig = getChartSettings(chartType, family);
        forIn(settings, (value, key) => {
          if (chartSettingsFromConfig[key] === undefined) {
            delete settings[key];
            return;
          }

          if (
            typeof chartSettingsFromConfig[key] !== typeof value &&
            chartSettingsFromConfig[key] !== null &&
            value !== null
          ) {
            settings[key] = chartSettingsFromConfig[key];
            return;
          }
        });

        forIn(chartSettingsFromConfig, (value, key) => {
          if (settings[key] === undefined) {
            settings[key] = value;
          }
        });

        return widgetConfig;
      });
      dashboard.layout = updatedLayout;
    });
    return preparedSettings as UserSettingsConfig;
  },
};
