import { Color, color } from "@amcharts/amcharts5";

import { extractTenantProps } from "@/helpers/unpacking";
import store from "@/store";

export const defaultColorSet: string[] = [
  "#4E5068",
  "#9567D9",
  "#F362E7",
  "#B5DEF0",
  "#FFB27D",
  "#E7F362",
  "#7DFFB2",
  "#8096FF",
  "#62E7F3",
  "#B59CD9",
  "#F3AFED",
  "#FFD4B7",
  "#F1F8A3",
  "#B6BC6A",
  "#859EA8",
  "#DFEBF0",
  "#946B8F",
  "#C19270",
];

export const getPaletteColors = (paletteIndex: number): Color[] => {
  const tenantColorSet = store.getters["auth/tenant"]?.props
    ? extractTenantProps(store.getters["auth/tenant"])?.palette
    : [];
  const colorSet = tenantColorSet?.length ? tenantColorSet : defaultColorSet;
  const palettes = [colorSet.map((el: string) => color(el))];
  return palettes[paletteIndex];
};

export const getColor = (paletteIndex: number, colorIndex: number): Color => {
  const colorSet = getPaletteColors(paletteIndex);
  const unknown_color = colorSet.at(-1) as Color;
  return colorSet.at(colorIndex) || unknown_color;
};
